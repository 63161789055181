import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../store';
import { Helmet } from "react-helmet";
// import PlaidLink from 'react-plaid-link'
import PropTypes from 'prop-types';
import PlaidEmbeddedComponent from '../common/PlaidEmbeddedComponent';
import ContactSupportComponent from '../common/ContactSupportComponent';

import { DeleteForever } from '@material-ui/icons';
import bankVerificationOperations from './duck/operations'

import './BankVerification.css';
const _ = require('lodash');

class BankVerificationContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      metadata: {}
      , opportunityUuid: ''
      , contactUuid: ''
      , submissions: []
      , token: ''
      , linkedBanks: []
      , plaidLinkOpen: ''
      , nextUrl: ''
    };
    this.handleOnSuccess = this.handleOnSuccess.bind(this);
    this.handleOnExit = this.handleOnExit.bind(this);
  }

  async componentDidMount() {
    this.setState({
      plaidLinkOpen: true
    });
    let opportunityUuid = this.props.match.params.opportunityUuid;
    let contactUuid = this.props.match.params.contactUuid;
    this.setState({contactUuid: contactUuid});
    this.setState({opportunityUuid: opportunityUuid});
    await this.props.getBankVerificationInitialData(opportunityUuid);
    const query = new URLSearchParams(this.props.location.search);
    let nexturl = query.get('next');
    if(nexturl) this.setState({nextUrl: nexturl});
  }

  async handleOnSuccess(token, metadata) {
    this.setState({
      plaidLinkOpen: false
    });
    let linkedBank = { token: token, metadata: metadata };
    let linkedBanks = this.state.linkedBanks;
    let indexedLinkedBanksToken = _.keyBy(linkedBanks, 'token');
    let institutionsIds = linkedBanks.map(linkedBank => { return linkedBank.metadata.institution.institution_id });
		if (!indexedLinkedBanksToken[token]) {
			let newLinkedBanks = linkedBanks.filter(linkedBankItem =>
				linkedBankItem.metadata.institution.institution_id !== linkedBank.metadata.institution.institution_id
			);

			newLinkedBanks.push(linkedBank);

			this.setState({ linkedBanks: newLinkedBanks }, async () => {
				await this.handleGetBankInformation(this.state.linkedBanks);
			});
		}
	}
	handleOnExit() {
    this.setState({
      plaidLinkOpen: false
    });
    // handle the case when your user exits Link
  }

  handlePlaidLinkOpen() {
    this.setState({
      plaidLinkOpen: true
    });
  }

  handleDelete(linkedBankParam) {
    let linkedBanks = this.state.linkedBanks;
    let newLinkedBanks = _.remove(linkedBanks, function (linkedBank) {
      return linkedBankParam.token !== linkedBank.token;
    });
    this.setState({
      linkedBanks: newLinkedBanks
    });
  }

  async handleGetBankInformation(linkedBanks) {
    await this.props.getBankData(linkedBanks, this.props.account.uuid, this.props.opportunity.uuid);
    if(this.state.nextUrl) history.push(this.state.nextUrl); //history.push('/redirect?url=' + this.state.nextUrl);
    // else history.push('/funding/process-done');
  }

  controlButtons = () => (
    <>
      <div className='container d-flex justify-content-between pt-2 mobile-none'>
        <button className='btn btn-primary mr-2' style={{ visibility: "visible" }} onClick={() => this.handlePlaidLinkOpen()}>
          {this.state.linkedBanks.length ? 'Link Another Bank Account(s)' : 'Link Your Bank Account(s)'}
        </button>
      </div>
      <div className='mobile'>
        <button className='btn btn-primary isDone p-2  mt-3' style={{ width: "100%"}}  onClick={() => this.handlePlaidLinkOpen()}>
          {this.state.linkedBanks.length ? 'Link Another Bank Account(s)' : 'Link Your Bank Account(s)'}
        </button>
      </div>
 
    </>
  );

  	render() {
    	return (
			<>
				<Helmet>
               <title>Uplyft Capital Funding | Bank-Verification</title>
        </Helmet>
				<div className="layoutFading space">
					<div id="bankVerificationContainer" className='container pt-3'>
						<div>
							<h2 className='pb-3 title page-title'> Linked Bank Account(s) </h2>
						</div>
						<div className='shadow p-5 mb-3 bg-white roundCorners'>
							<table id='bankVerification' className='table table-striped table-purple'>
								<tbody>
									{this.state.linkedBanks.length > 0 ?
									this.state.linkedBanks.map((linkedBank, i) =>
									<tr key={i}>
										<td className=''>{linkedBank.metadata.institution.name}</td>
										<td className=''>
											{linkedBank.metadata.accounts.map((account, j) =>
											<p key={j} className='content'>{account.name + " ..." + account.mask}</p>
											)}
										</td>
										<td className='text-right'>
											<a onClick={() => this.handleDelete(linkedBank)}>
											  <DeleteForever color="error" />
											</a>
										</td>
									</tr>) :
									<tr>
									<td className='col-md-12'>
										<p>No Banks Account(s) Linked</p>
									</td>
									</tr>
								}
							</tbody>
							</table>
							<div className='display'><ContactSupportComponent /></div>
						</div>
						{this.controlButtons()}
						<div className='isDone pt-3'><ContactSupportComponent /></div>
					</div>
					<div>
						{this.state.plaidLinkOpen ?
							<PlaidEmbeddedComponent
							clientName="Uplyft Capital Verification"
              env="production"
              publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY}
              // env="sandbox"
              // publicKey={REACT_APP_PLAID_PUBLIC_KEY_SANDBOX}
							product={["auth", "transactions", "assets"]}
              // webhook = "https://stagingcrm.uplyftcapital.com/webhooks/plaid/eventListener"
							onExit={this.handleOnExit}
							onSuccess={this.handleOnSuccess}>
							</PlaidEmbeddedComponent>
							: ''
						}
					</div>
				</div>
			</>
    	);
  	}
}

BankVerificationContainer.propTypes = {
  getBankData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  opportunityUuid: state.bankVerification.opportunityUuid
  , account: state.bankVerification.account
  , contacts: state.bankVerification.contacts
  , opportunity: state.bankVerification.opportunity
});

const mapDispatchToProps = dispatch => {
  const getBankVerificationInitialData = (opportunityUuid) => dispatch(bankVerificationOperations.getBankVerificationInitialData(opportunityUuid));

  const getBankData = (linkedBanks, accountUuid, opportunityUuid) => bankVerificationOperations.getBankData(linkedBanks, accountUuid, opportunityUuid);
  return {
    getBankVerificationInitialData,
    getBankData,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(BankVerificationContainer);
